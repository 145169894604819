"use client"

import {
  BrowsingPlatform,
  Filters,
  ParsedBrowsingState,
  RatingFilterQuery,
  SortQuery,
} from "@store-platform/types"
import {
  parseBrowsingState,
  parseFiltersFromSegments,
  parseSegmentsFromString,
} from "@store-platform/utils"
import { useParams, useSearchParams } from "next/navigation"
import { create } from "zustand"
import { useEffect, useMemo } from "react"
import { useShallow } from "zustand/react/shallow"

type StoreActions = {
  set: (state: ParsedBrowsingState) => void
}

export const useClientBrowseStateStore = create<
  ParsedBrowsingState & StoreActions
>((set) => ({
  filters: undefined,
  searchQuery: undefined,
  attributes: undefined,
  platform: undefined,
  set: (state: ParsedBrowsingState) => set(state),
}))

export const useClientBrowseState = (): ParsedBrowsingState => {
  const params = useParams<{
    platform?: BrowsingPlatform
    segments?: string
    query?: string
  }>()
  const searchParams = useSearchParams()
  const store = useClientBrowseStateStore()
  const setStore = useClientBrowseStateStore(useShallow((state) => state.set))

  useEffect(() => {
    if (params.query) {
      const filters: Filters = {}
      for (const [key, value] of searchParams.entries()) {
        if (key === "sort_by") {
          filters.sort_by = value as SortQuery
        } else if (key === "min_rating") {
          filters.min_rating = value as RatingFilterQuery
        } else if (key === "attribute") {
          if (!filters.attribute) filters.attribute = []
          filters.attribute?.push(value)
        }
      }
      const browsingState = parseBrowsingState(params.platform, filters)
      browsingState.searchQuery = params.query
      setStore(browsingState)
    } else {
      const segments = parseSegmentsFromString(params?.segments ?? "")
      const browsingState = {
        ...parseBrowsingState(
          params.platform,
          parseFiltersFromSegments(segments ?? []),
        ),
        searchQuery: undefined,
      }
      setStore(browsingState)
    }
  }, [params.query, params?.segments, params.platform, searchParams, setStore])

  return useMemo(
    () => ({
      filters: store.filters,
      searchQuery: store.searchQuery,
      attributes: store.attributes,
      platform: store.platform,
    }),
    [store],
  )
}
