"use client"
import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Categories,
  categoryFeatured,
  cn,
  generateSegmentUrl,
  getToggleAttributeLink,
  isPrimaryCategory,
} from "@store-platform/utils"
import Link from "next/link"
import { SvgArrowLeft } from "@store-platform/ui/icons"
import { ParsedBrowsingState, PrimaryCategory } from "@store-platform/types"
import { useClientBrowseState } from "@store-platform/ui/common"

const MobilePrimaryCategories = (props: {
  category?: string
  link: (to: ParsedBrowsingState) => string
}) => {
  return (
    <div className="flex flex-row sm:hidden snap-x overflow-x-scroll scrollbar-hide items-center bg-white border border-b-2">
      {Object.values(Categories).map((category) => {
        const selected = category === Categories.featured
        return (
          <Link
            scroll={false}
            prefetch={true} // https://github.com/vercel/next.js/issues/61259
            key={category.id}
            className={cn(
              selected
                ? "border-b-2 border-gray-900 text-gray-900"
                : "text-gray-600 hover:text-gray-800",
              "without-ring block cursor-pointer snap-start content-center p-2 text-xs font-medium scrollbar-hide focus:outline-none focus:ring-0 whitespace-nowrap",
            )}
            href={props.link({ attributes: [category.id] })}
          >
            <div className="flex justify-center">
              {category.icon && <category.icon className="w-5 h-5" />}
            </div>
            <div className="flex justify-center">{category.name}</div>
          </Link>
        )
      })}
    </div>
  )
}

const CategoryPill = ({ category }: { category: PrimaryCategory }) => {
  const browsingState = useClientBrowseState()
  const selected = category.id === categoryFeatured.id
  return (
    <Link
      prefetch={true} // https://github.com/vercel/next.js/issues/61259
      href={getToggleAttributeLink(browsingState, category.id, true)}
      className={cn(
        "relative flex items-center justify-center snap-start h-11 rounded-full text-sm font-medium px-4 cursor-pointer transition-colors ease-out",
        selected
          ? "bg-neutral-900 text-white"
          : "bg-white text-gray-600 hover:text-gray-800",
        {
          "sm:scroll-ml-14 sm:scroll-mr-14": category.position !== 0, // desktop (account for left arrow for scroll snap)
          "scroll-ml-2 sm:scroll-ml-unset": true, // mobile (snap to match left edge of content)
        },
      )}
    >
      <span className="flex items-center justify-center gap-x-2">
        {isPrimaryCategory(category) && <category.icon className="w-5 h-5" />}
        <span className="truncate">{category.name}</span>
      </span>
    </Link>
  )
}

function ArrowButton({
  direction,
  onClick,
}: {
  direction: "left" | "right"
  onClick: () => void
}) {
  return (
    <div
      className={cn(
        `hidden md:flex absolute w-20 bottom-0 pointer-events-none`,
        {
          "left-0 justify-start": direction === "left",
          "right-0 justify-end": direction === "right",
        },
      )}
      style={{
        background: `linear-gradient(to ${
          direction === "right" ? "left" : "right"
        }, #f7f7f7, #f7f7f7 58%,  #f7f7f7f2 60%, #f7f7f700 80%)`,
      }}
    >
      <div className="pointer-events-auto">
        <button
          onClick={onClick}
          className="relative rounded-full h-11 text-sm px-[14px] bg-transparent cursor-pointer transition-colors ease-out"
        >
          <span className="flex items-center justify-center gap-x-2">
            <SvgArrowLeft
              className={cn("w-5 h-5", {
                "rotate-180": direction === "right",
              })}
            />
          </span>
        </button>
      </div>
    </div>
  )
}

export function CategoryBar(props: { selectedCategory: string }) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(true)
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current
      if (!container) return
      if (container?.scrollLeft >= 0 && !showLeftArrow) {
        setShowLeftArrow(true)
      } else if (container?.scrollLeft <= 0 && showLeftArrow) {
        setShowLeftArrow(false)
      }

      const isScrollAtEnd =
        container?.scrollWidth - container?.scrollLeft ===
        container?.clientWidth
      setShowRightArrow(!isScrollAtEnd)
    }

    const container = containerRef.current
    container?.addEventListener("scroll", handleScroll)
    return () => container?.removeEventListener("scroll", handleScroll)
  }, [containerRef, showLeftArrow, showRightArrow])

  useEffect(() => {
    if (
      containerRef.current &&
      containerRef.current?.scrollWidth - containerRef.current?.scrollLeft ===
        containerRef.current?.clientWidth
    ) {
      setShowRightArrow(false)
    }
  }, [])

  // scan left / right
  const handleScroll = useCallback((direction: "left" | "right") => {
    const element = containerRef.current
    if (element) {
      const scrollByWidth = element.clientWidth * 0.8
      element.scrollBy({
        left: direction === "left" ? -scrollByWidth : scrollByWidth,
        behavior: "smooth",
      })
    }
  }, [])

  const siblings = Object.values(Categories)

  return (
    <nav
      className={cn(
        "fixed top-[64px] bg-gray-50 !z-30 w-full",
        "max-sm:pb-2 max-sm:space-y-2",
        "sm:pr-3 sm:mx-3 sm:top-[84px]",
        // max-w-[calc(100vw-92px)] = screen width - (sidebar width (80px) + left layout padding (12px)) - see ../layout.tsx
        // lg:max-w-[calc(100vw-236px)] = screen width - (sidebar width (224px) + left layout padding (12px))
        "sm:w-[calc(100vw-92px)] lg:w-[calc(100vw-236px)] 2xl:w-[1256px]",
      )}
    >
      <div className="sm:py-4">
        <MobilePrimaryCategories
          category={props.selectedCategory}
          link={generateSegmentUrl}
        />

        <div className="flex items-center gap-4 px-2 sm:px-0">
          <div className="relative min-w-0">
            <div
              className="scrollbar-hide relative flex gap-2 snap-x overflow-x-scroll overflow-y-hidden items-center max-h-0 sm:max-h-full py-0 opacity-0 sm:opacity-100" // (-) margin (+) padding to keep cnips visible to screen edge on mobile
              ref={containerRef}
            >
              {siblings.map((category) => (
                <CategoryPill key={category.id} category={category} />
              ))}
            </div>
            {showLeftArrow && (
              <ArrowButton
                direction="left"
                onClick={() => handleScroll("left")}
              />
            )}
            {showRightArrow && (
              <ArrowButton
                direction="right"
                onClick={() => handleScroll("right")}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  )
}
