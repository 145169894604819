"use client"
import { useEffect, useState } from "react"
import { cn } from "@store-platform/utils"

const ANIMATION_DURATION = 500
const ANIMATE_IN = "translate-y-full opacity-0"
const ANIMATE_OUT = "translate-y-0 opacity-100"

const WordCycler = (props: { words: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true)
      setTimeout(() => {
        setIsAnimating(false)
        setCurrentIndex((currentIndex + 1) % props.words.length)
      }, ANIMATION_DURATION)
    }, ANIMATION_DURATION * props.words.length)

    return () => clearInterval(interval)
  }, [currentIndex, props.words.length])

  return (
    <div className="relative h-8">
      {props.words.map((word, index) => (
        <span
          key={word}
          className={cn(
            `absolute text-blue-500 font-semibold w-full transition-all ease-in-out duration-500`,
            props.words.length > 1
              ? index === currentIndex
                ? isAnimating
                  ? ANIMATE_IN
                  : ANIMATE_OUT
                : index === (currentIndex + 1) % props.words.length
                  ? isAnimating
                    ? ANIMATE_OUT
                    : ANIMATE_IN
                  : ANIMATE_IN
              : null,
          )}
        >
          {word}
        </span>
      ))}
    </div>
  )
}

export default WordCycler
